import React from "react";
import {Link} from 'gatsby'

import Layout from "../components/Layout";
import data from "../../content/resources/aliados.json";
import cluentes from '../../content/resources/clientes.json'
import Caroucel from "../components/Caroucel";

const IndexPage = () => (
        <Layout>
            <div id="main">
                <Caroucel/>
                <div className="inner">
                    <section id="about_us">
                        <div className="home">
                            <h1 className="home__title">¿Quiénes somos?</h1>
                        </div>

                        <div className="card">
                            <div className="card__content">
                                <h1 className="home__title_card">FOXGLOBE</h1>
                                <p className="home__parrafo_card">Somos la empresa líder en la industria de la información,
                                    ofreciendo productos y soluciones a los sectores académico, científico y cultural,
                                    mediante recursos electrónicos, libros impresos, herramientas tecnológicas,
                                    infraestructura e innovación continua.</p>
                                <Link to="/about" className="button">Más información</Link>
                            </div>
                        </div>
                    </section>
                    <section id="clientes">
                        <div className="home">
                            <h1 className="home__title">Clientes</h1>
                        </div>
                        <section className="clientes">
                            {cluentes.slice(0, 10).map((cliente) => {
                                return (
                                    <article key={cliente.id}>
                                    <span className="image">
                                        <img
                                            src={cliente.image}/>
                                    </span>
                                    </article>
                                )
                            })}
                        </section>
                    </section>

                    <section id="services">
                        <div className="home">
                            <h1 className="home__title">Aliados</h1>
                        </div>
                        <section className="aliados">
                            {data.slice(0, 6).map((service) => {
                                return (
                                    <article key={service.id}>
                                    <span className="image">
                                        <img
                                            src={service.image}/>
                                    </span>
                                    </article>
                                )
                            })}
                        </section>
                    </section>
                </div>
            </div>
        </Layout>
    )
;

export default IndexPage;
