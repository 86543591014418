import React from "react";
import Swiper from "react-id-swiper";

const Autoplay = () => {
    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    };

    return (
        <Swiper {...params}>
            <div className="slide1 slide-content">
                <h4>Líderes en Soluciones Integrales de Información Académica, Científica y Cultural</h4>
            </div>
            <div className="slide2 slide-content">
                <h4>Programa de Fortalecimiento de los  Servicios de Educación Especial (PFSEE)</h4>
            </div>
            <div className="slide3 slide-content">
                <h4>Plataforma y libros para la enseñanza y aprendizaje del idioma Inglés</h4>
            </div>
            <div className="slide4 slide-content">
                <h4>Programa de Expansión de la Educación Inicial (PEEI)</h4>
            </div>
            <div className="slide5 slide-content">
                <h4>Libros Electrónicos</h4>
            </div>
            <div className="slide6 slide-content">
                <h4>Investigación Científica</h4>
            </div>
            <div className="slide7 slide-content">
                <h4>Sector Salud</h4>
            </div>
            <div className="slide8 slide-content">
                <h4>Realidad Virtual</h4>
            </div>
        </Swiper>
    );
};

export default Autoplay;

